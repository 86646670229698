import React, { useEffect, Suspense } from 'react'

import { Route, Switch, useHistory } from "react-router-dom"
import { useMatomo } from '@datapunt/matomo-tracker-react'

import { Loader } from 'semantic-ui-react'

const THPlayer = React.lazy(() => import('../../Trove.Player'))
const THCreator = React.lazy(() => import('../../Trove.Creator'))
const THPage = React.lazy(() => import('../../Trove.Page'))
const THBrowse = React.lazy(() => import('../../Trove.Browse'))

export const RouterWrapper = () => {
    const { trackPageView } = useMatomo();
    const history = useHistory();

    // Track the initial page view
    trackPageView();

    useEffect(() => {
        return history.listen((location) => {
            // Track all subsequent page views
            trackPageView()
        })
    }, [history, trackPageView]);

    return (
        <Suspense fallback={<Loader />}>
            <Switch>
                <Route path="/play/:hunt?/:locationHash?" exact component={THPlayer} />
                <Route path="/create/:hunt?" exact component={THCreator} />
                <Route path="/browse" exact component={THBrowse} />
                <Route path="/:slug?" component={THPage} />
            </Switch>
        </Suspense>
    )
};