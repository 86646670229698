import React from 'react'
import ReactDOM from 'react-dom'

import 'semantic-ui-less/semantic.less'
import './semantic-ui/fonts.css'

import config from "./helpers/configLoader";

import { RouterWrapper } from './components/RouterWrapper'
import { BrowserRouter as Router } from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'

import { createInstance, MatomoProvider } from '@datapunt/matomo-tracker-react'
import * as serviceWorker from './serviceWorker'

const instance = createInstance({
    urlBase: config.analyticsRoot,
    siteId: 1,
    configurations: {
        // ToDo: Find out why this isn't working
        disableCookies: true,
    },
});

ReactDOM.render(
    <CookiesProvider>
        <MatomoProvider value={instance}>
            <Router basename={ process.env.PUBLIC_URL }>
                <RouterWrapper />
            </Router>
        </MatomoProvider>
    </CookiesProvider>
    , document.getElementById('Trove'));

document.getElementById('appLoader').remove();

serviceWorker.register({
    onUpdate: registration => {
        if (registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
        window.location.reload();
    }
});

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);
let reszieTimeout;
window.addEventListener('resize', () => {
    clearTimeout(reszieTimeout);
    reszieTimeout = setTimeout(function() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, 200);
});